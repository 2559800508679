<template>
  <div class="home">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item class="dangq">当前位置：</el-breadcrumb-item>
      <el-breadcrumb-item class="dangq"
        ><router-link to="/">汇成国际</router-link></el-breadcrumb-item
      >
      <el-breadcrumb-item class="dangq"
        ><router-link to="#">入伙</router-link></el-breadcrumb-item
      >
    </el-breadcrumb>
    <div class="link">
      <router-link to="/joinRaise" class="link1 link2">募集中产品</router-link>
      <router-link to="/joinOperation" class="link1">操作中产品</router-link>
      <router-link to="/joinWinding" class="link1">已清盘产品</router-link>
    </div>
    <div class="search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="合伙名称:">
          <el-input v-model="formInline.user" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="操盘手:" class="cao">
          <el-input v-model="formInline.user2" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="radio">
      <div class="zhouqi">操作周期:</div>
      <div
        :class="currentIndex == item.id ? 'radios activeRadio' : 'radios'"
        @click="radio(item.id)"
        v-for="(item, index) in radioText"
        :key="index"
      >
        {{ item.text }}
      </div>
    </div>
    <div class="conts">
      <div class="hengx"></div>
      <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部产品" name="1">
          <div class="conts_nei">
            <!-- title -->
            <div class="conts_title">
              <div class="titles">合伙名称</div>
              <div class="titles">操盘手</div>
              <div class="titles">红包状态</div>
              <div class="titles">操作周期</div>
              <div class="titles" @click="ruhuo">
                入伙年化收益
                <div class="sort-img">
                  <img ref="s1" :src="top1" alt="" />
                  <img ref="s2" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles" @click="jine">
                已募集金额(元)
                <div class="sort-img">
                  <img ref="s3" :src="top1" alt="" />
                  <img ref="s4" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles" @click="jindu">
                募集进度(风险金)
                <div class="sort-img">
                  <img ref="s5" :src="top0" alt="" />
                  <img ref="s6" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles" @click="time">
                截止募集时间
                <div class="sort-img">
                  <img ref="s7" :src="top1" alt="" />
                  <img ref="s8" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles">操作</div>
            </div>
            <!-- 循环 -->
            <div class="lli">
              <div
                class="conts_title cont_list"
                v-for="(item, index) in list"
                :key="index"
                @click="
                  $router.push({ path: '/collection', query: { id: item.id } })
                "
              >
                <div class="titles zicolor">{{ item.title }}</div>
                <div class="titles zicolor">{{ item.name }}</div>
                <div class="titles zicolor">
                  <img
                    v-show="item.red_packet == 1"
                    src="../../assets/img/redbag.png"
                    class="resbag"
                    alt=""
                  />
                </div>
                <div class="titles zicolor">{{ item.operate_days }}</div>
                <div class="titles zicolor redcolor">
                  {{ item.annual_profit / 100 }}% +{{item.float_profit/100}}%
                </div>
                <div class="titles zicolor">{{ item.amount / 100 }}</div>
                <div class="titles zicolor">
                  {{ item.planned_speed / 100 }}%
                </div>
                <div class="titles zicolor">
                  <!-- {{ (item.raise_end * 1000) | format("YYYY-MM-DD HH:mm") }} -->
                  <van-count-down :time="item.raise_end * 1000 - date" format="DD天HH小时mm分钟"/>
                </div>
                <div class="titles zicolor lancolor">入伙</div>
              </div>
            </div>
            <!-- 循环结束 -->
            <el-pagination
              class="page"
              :page-size="limit"
              background
              layout="prev, pager, next"
              :total="count"
              :current-page="page"
              @current-change="handleSizeChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="可使用红包产品" name="2">
          <div class="conts_nei">
            <!-- title -->
            <div class="conts_title">
              <div class="titles">合伙名称</div>
              <div class="titles">操盘手</div>
              <div class="titles">红包状态</div>
              <div class="titles">操作周期</div>
              <div class="titles" @click="ruhuos">
                入伙年化收益
                <div class="sort-img">
                  <img ref="s1s" :src="top1" alt="" />
                  <img ref="s2s" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles" @click="jines">
                已募集金额(元)
                <div class="sort-img">
                  <img ref="s3s" :src="top1" alt="" />
                  <img ref="s4s" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles" @click="jindus">
                募集进度(风险金)
                <div class="sort-img">
                  <img ref="s5s" :src="top0" alt="" />
                  <img ref="s6s" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles" @click="times">
                截止募集时间
                <div class="sort-img">
                  <img ref="s7s" :src="top1" alt="" />
                  <img ref="s8s" :src="down1" alt="" />
                </div>
              </div>
              <div class="titles">操作</div>
            </div>
            <!-- 循环 -->
            <div class="lli">
              <div
                class="conts_title cont_list"
                v-for="(item, index) in list"
                :key="index"
                @click="
                  $router.push({ path: '/collection', query: { id: item.id } })
                "
              >
                <div class="titles zicolor">{{ item.title }}</div>
                <div class="titles zicolor">{{ item.name }}</div>
                <div class="titles zicolor">
                  <img
                    v-show="(item.red_packet == 1) == 1"
                    src="../../assets/img/redbag.png"
                    class="resbag"
                    alt=""
                  />
                </div>
                <div class="titles zicolor">{{ item.operate_days }}</div>
                <div class="titles zicolor redcolor">
                  {{ item.annual_profit / 100 }}% +{{item.float_profit/100}}%
                </div>
                <div class="titles zicolor">{{ item.amount / 100 }}</div>
                <div class="titles zicolor">
                  {{ item.planned_speed / 100 }}%
                </div>
                <div class="titles zicolor">
                  {{ (item.raise_end * 1000) | format("YYYY-MM-DD") }}
                </div>
                <div class="titles zicolor lancolor">入伙</div>
              </div>
            </div>
            <!-- 循环结束 -->
            <el-pagination
              class="page"
              :page-size="limit"
              background
              layout="prev, pager, next"
              :total="count"
              :current-page="page"
              @current-change="handleSizeChange"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getAdmissionDetail } from "@network/admissionDetail";
export default {
  name: "Join",
  data() {
    return {
      date: new Date(),
      list: [],
      top0: require("../../assets/img/upl.png"),
      top1: require("../../assets/img/uph.png"),
      down0: require("../../assets/img/downl.png"),
      down1: require("../../assets/img/downh.png"),
      color: "#999999",
      backgroundUrl: require("@/assets/img/danxuan.png"),
      currentIndex: 0, //操作周期
      radioText: [
        { id: 0, text: "不限制" },
        { id: 1, text: "1-7天" },
        { id: 2, text: "7-30天" },
        { id: 3, text: "1-3个月" },
        { id: 4, text: "3-12个月" },
        { id: 5, text: "1年以上" },
      ],
      //搜索框
      formInline: {
        user: "",
        user2: "",
      },
      activeName: "1",
      method: 6, //1 入伙年化收益 2 募集金额(元) 3 募集进度(风险金) 4 截止募集时间
      sort: "desc", //asc：正序，desc：倒序

      page: 1,
      limit: 8,
      count: 0,
      redPacket: "", //是否可使用红包
    };
  },

  created() {
    this.invest();
  },
  components: {},
  methods: {
    //获取
    invest() {
      getAdmissionDetail(
        this.page,
        this.limit,
        this.method,
        this.sort,
        this.formInline.user,
        this.formInline.user2,
        this.currentIndex,
        this.redPacket,
        ""
      ).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({
              message:"请重新登录",
              offset:300
            });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取失败",
            offset:300
          });
          return false;
        }
        this.list = res.data;
        this.count = res.count;
      });
    },

    //处理当前页变动时触发的事件
    handleSizeChange(val) {
      this.page = val;
      this.invest();
    },

    ruhuo() {
      if (this.method == 1) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s1.src = this.top0;
          this.$refs.s2.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s1.src = this.top1;
          this.$refs.s2.src = this.down0;
        }
      } else {
        this.$refs.s1.src = this.top1;
        this.$refs.s2.src = this.down0;
        this.$refs.s3.src = this.top1;
        this.$refs.s4.src = this.down1;
        this.$refs.s5.src = this.top1;
        this.$refs.s6.src = this.down1;
        this.$refs.s7.src = this.top1;
        this.$refs.s8.src = this.down1;
        this.sort = "asc";
        this.method = 1;
      }
      this.invest();
    },
    jine() {
      if (this.method == 2) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s3.src = this.top0;
          this.$refs.s4.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s3.src = this.top1;
          this.$refs.s4.src = this.down0;
        }
      } else {
        this.$refs.s1.src = this.top1;
        this.$refs.s2.src = this.down1;
        this.$refs.s3.src = this.top1;
        this.$refs.s4.src = this.down0;
        this.$refs.s5.src = this.top1;
        this.$refs.s6.src = this.down1;
        this.$refs.s7.src = this.top1;
        this.$refs.s8.src = this.down1;
        this.sort = "asc";
        this.method = 2;
      }
      this.invest();
    },
    jindu() {
      if (this.method == 6) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s5.src = this.top0;
          this.$refs.s6.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s5.src = this.top1;
          this.$refs.s6.src = this.down0;
        }
      } else {
        this.$refs.s1.src = this.top1;
        this.$refs.s2.src = this.down1;
        this.$refs.s3.src = this.top1;
        this.$refs.s4.src = this.down1;
        this.$refs.s5.src = this.top1;
        this.$refs.s6.src = this.down0;
        this.$refs.s7.src = this.top1;
        this.$refs.s8.src = this.down1;
        this.sort = "asc";
        this.method = 6;
      }
      this.invest();
    },
    time() {
      if (this.method == 3) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s7.src = this.top0;
          this.$refs.s8.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s7.src = this.top1;
          this.$refs.s8.src = this.down0;
        }
      } else {
        this.$refs.s1.src = this.top1;
        this.$refs.s2.src = this.down1;
        this.$refs.s3.src = this.top1;
        this.$refs.s4.src = this.down1;
        this.$refs.s5.src = this.top1;
        this.$refs.s6.src = this.down1;
        this.$refs.s7.src = this.top1;
        this.$refs.s8.src = this.down0;
        this.sort = "asc";
        this.method = 3;
      }
      this.invest();
    },
    //可使用红包产品 排序
    ruhuos() {
      if (this.method == 1) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s1s.src = this.top0;
          this.$refs.s2s.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s1s.src = this.top1;
          this.$refs.s2s.src = this.down0;
        }
      } else {
        this.$refs.s1s.src = this.top1;
        this.$refs.s2s.src = this.down0;
        this.$refs.s3s.src = this.top1;
        this.$refs.s4s.src = this.down1;
        this.$refs.s5s.src = this.top1;
        this.$refs.s6s.src = this.down1;
        this.$refs.s7s.src = this.top1;
        this.$refs.s8s.src = this.down1;
        this.sort = "asc";
        this.method = 1;
      }
      this.invest();
    },
    jines() {
      if (this.method == 2) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s3s.src = this.top0;
          this.$refs.s4s.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s3s.src = this.top1;
          this.$refs.s4s.src = this.down0;
        }
      } else {
        this.$refs.s1s.src = this.top1;
        this.$refs.s2s.src = this.down1;
        this.$refs.s3s.src = this.top1;
        this.$refs.s4s.src = this.down0;
        this.$refs.s5s.src = this.top1;
        this.$refs.s6s.src = this.down1;
        this.$refs.s7s.src = this.top1;
        this.$refs.s8s.src = this.down1;
        this.sort = "asc";
        this.method = 2;
      }
      this.invest();
    },
    jindus() {
      if (this.method == 6) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s5s.src = this.top0;
          this.$refs.s6s.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s5s.src = this.top1;
          this.$refs.s6s.src = this.down0;
        }
      } else {
        this.$refs.s1s.src = this.top1;
        this.$refs.s2s.src = this.down1;
        this.$refs.s3s.src = this.top1;
        this.$refs.s4s.src = this.down1;
        this.$refs.s5s.src = this.top1;
        this.$refs.s6s.src = this.down0;
        this.$refs.s7s.src = this.top1;
        this.$refs.s8s.src = this.down1;
        this.sort = "asc";
        this.method = 6;
      }
      this.invest();
    },
    times() {
      if (this.method == 3) {
        if (this.sort == "asc") {
          this.sort = "desc";
          this.$refs.s7s.src = this.top0;
          this.$refs.s8s.src = this.down1;
        } else if (this.sort == "desc") {
          this.sort = "asc";
          this.$refs.s7s.src = this.top1;
          this.$refs.s8s.src = this.down0;
        }
      } else {
        this.$refs.s1s.src = this.top1;
        this.$refs.s2s.src = this.down1;
        this.$refs.s3s.src = this.top1;
        this.$refs.s4s.src = this.down1;
        this.$refs.s5s.src = this.top1;
        this.$refs.s6s.src = this.down1;
        this.$refs.s7s.src = this.top1;
        this.$refs.s8s.src = this.down0;
        this.sort = "asc";
        this.method = 3;
      }
      this.invest();
    },
    radio(id) {
      console.log(id);
      this.currentIndex = id;
      this.invest();
    },
    handleClick() {
      console.log(this.activeName);
      if (this.activeName == 2) {
        this.redPacket = "1";
        this.sort = "desc";
        this.method == 6;
      } else if (this.activeName == 1) {
        this.redPacket = "";
      }
      this.invest();
    },
    onSubmit() {
      console.log(this.formInline.user);
      console.log(this.formInline.user2);
      this.invest();
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../assets/css/join.less";
.titles {
  user-select: none;
}
</style>
